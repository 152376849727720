import React, { useState } from "react";
import MTextField from "../../../../Atoms/MTextField";
import MuiPopover from "../../../../Atoms/Popover";
import MenuOption from "../../../../Molecules/MenuOption";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import FilterListIcon from "@mui/icons-material/FilterList";

import classes from "./index.module.css";
import MBottomSheet from "../../../../Atoms/MBottomSheet";
import MTypography from "../../../../Atoms/MTypography";
import { FormControl, FormControlLabel, MenuItem, Radio, RadioGroup, Typography } from "@mui/material";
import { contentFiltersOption } from "../../Brands/config";
import { isExportButton, showProductVisibilityFilter } from "../../../../../utils/helpers/priviligesChecks";
import { useSelector } from "react-redux";
import { DbUserSelector, UserRoleSelector } from "../../../../../store/user/user.selector";
import { CompanyRoleSelector } from "../../../../../store/company/company.selector";
import MCheckbox from "../../../../Atoms/MCheckbox";
import MButton from "../../../../Atoms/MButton";

interface SavedProductsState {
  loading: boolean;
  openPopper: boolean;
  anchorEl: SVGSVGElement | null;
}

interface DefaultMenu {
  label: string;
  value: any;
}

interface AsinsViewToolbarMProps {
  state: SavedProductsState;
  setState: React.Dispatch<React.SetStateAction<SavedProductsState>>;
  handleCsvExport: () => void;
  enableSelectionMode: () => void;
  handleSearchChange: (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
  handleFilterChange: (value: string, showInternalProduct: boolean, showApprovedProducts: boolean) => void;
  search: string;
  hideInternalUsersProducts: boolean;
  productLiveFilter: string;
  handleRefresh: () => void;
  hideProductVisibilityFilter?: boolean;
  showApprovedProducts: boolean;
}

const AsinsViewToolbarM = ({
  state,
  handleCsvExport,
  enableSelectionMode,
  setState,
  handleSearchChange,
  search,
  hideInternalUsersProducts,
  productLiveFilter,
  handleFilterChange,
  handleRefresh,
  hideProductVisibilityFilter,
  showApprovedProducts
}: AsinsViewToolbarMProps) => {
  const [showFilter, setShowFilter] = useState<boolean>(false);
  const [selectedRadio, setSelectedRadio] = React.useState<string>(productLiveFilter);
  const [checkbox, setCheckbox] = useState(hideInternalUsersProducts);
  const [showApprovedCheckBox, setShowApprovedCheckBox] = useState(showApprovedProducts);
  const userRole = useSelector(UserRoleSelector);
  const dbUser = useSelector(DbUserSelector);
  const companyRole = useSelector(CompanyRoleSelector);

  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedRadio(event.target.value);
  };

  const handleClick = (clickType: string) => {
    if (clickType === "applyFilters") {
      handleFilterChange(selectedRadio, checkbox, showApprovedCheckBox);
      setShowFilter(false);
    } else {
      setCheckbox(hideInternalUsersProducts);
      setSelectedRadio(productLiveFilter);
      setShowApprovedCheckBox(showApprovedCheckBox)
      setShowFilter(false);
    }
  };
  return (
    <div className="d-flex w-100 justify-content-between align-items-center gap-3">
      <MTextField
        icon={"search"}
        position={"start"}
        onChange={handleSearchChange}
        name={"search-field"}
        placeholder={"Search"}
        value={search}
        margin={"dense"}
        rootClass={classes.TextInput}
      />

      {/* icons container */}
      <div className="d-flex align-items-center gap-2">
        {/* sorting icon */}
        <div>{/* sroting icon is removed temporary on 1/22/24 ui available in story-943 commit */}</div>

        {/* Publish status icon */}
        <div>
          <div className={`${classes.IconContainer}`} onClick={() => setShowFilter(true)}>
            <FilterListIcon />
          </div>

          <MBottomSheet open={showFilter} showFullHeight={true}>
            <div>
              <div>
                <FormControl component="fieldset">
                  <Typography variant="h6" className={`${classes.Headers} pt-3 px-3 pb-2`}>
                    Publish Status
                  </Typography>
                  <RadioGroup
                    aria-label="publish-status"
                    name="publish-status"
                    value={selectedRadio}
                    onChange={(e) => {
                      handleRadioChange(e);
                    }}
                    sx={{ paddingLeft: "16px" }}
                  >
                    {contentFiltersOption?.map((mItem: DefaultMenu) => (
                      <FormControlLabel
                        key={mItem.label}
                        value={mItem.value}
                        control={<Radio />}
                        label={mItem.label}
                        sx={{ height: "24px", mb: "10px", fontSize: "14px" }}
                        classes={{
                          label: selectedRadio === mItem.value ? classes.ActiveLabel : classes.NonActiveLabel,
                        }}
                      />
                    ))}
                  </RadioGroup>
                </FormControl>
              </div>
              {/* will only show to Owner, Internal or Admin */}
              {showProductVisibilityFilter(userRole) && !hideProductVisibilityFilter && (
                <span>
                  <Typography variant="h6" className={`${classes.Headers} pt-1 px-3 pb-2 `}>
                    Product Visibility
                  </Typography>
                  <MenuItem
                    value={"hide_internal_users"}
                    className={`p-0 ${classes.MenuItem}`}
                    sx={{ paddingLeft: "6px !important" }}
                    onClick={() => setCheckbox(!checkbox)}
                  >
                    <MCheckbox variant={"square"} checked={checkbox} size="small" />
                    <MTypography
                      variant="subtitle1"
                      customClass={checkbox ? classes.ActiveColor : classes.NonActiveColor}
                    >
                      Hide Internal User Products
                    </MTypography>
                  </MenuItem>
                  <MenuItem
                    value={"isApprovedProducts"}
                    className={`p-0 ${classes.MenuItem}`}
                    sx={{ paddingLeft: "6px !important" }}
                    onClick={() => setShowApprovedCheckBox(!showApprovedCheckBox)}
                  >
                    <MCheckbox variant={"square"} checked={showApprovedCheckBox} size="small" />
                    <MTypography
                      variant="subtitle1"
                      customClass={showApprovedCheckBox ? classes.ActiveColor : classes.NonActiveColor}
                    >
                      Show Approved Products
                    </MTypography>
                  </MenuItem>
                </span>
              )}
              <div className={`d-flex justify-content-end gap-2 px-3 mw-100 pt-4 pb-2 ${classes.ButtonsContainer}`}>
                <MButton variant="outlined" size={"small"} onClick={() => handleClick("cancel")}>
                  Cancel
                </MButton>
                <MButton size={"small"} onClick={() => handleClick("applyFilters")}>
                  Apply Filters
                </MButton>
              </div>
            </div>
          </MBottomSheet>
        </div>

        {/* three dots select/csv/refresh */}
        <div>
          <div className={`${classes.IconContainer}`}>
            <MoreVertIcon
              className={`${classes.IconColor}`}
              onClick={(e) => {
                setState({
                  ...state,
                  anchorEl: e.currentTarget,
                  openPopper: true,
                });
              }}
            />
          </div>
          <MuiPopover
            open={state.openPopper}
            anchorEl={state.anchorEl}
            onClose={() => setState({ ...state, openPopper: false })}
          >
            <div className={"py-2"}>
              {!isExportButton(dbUser?.role, dbUser?.companyID, companyRole) && (
                <MenuOption>
                  <div className={"cursor-pointer"} onClick={handleCsvExport}>
                    {"Export CSV/Excel file"}
                  </div>
                </MenuOption>
              )}
              <MenuOption>
                <div className={"cursor-pointer"} onClick={enableSelectionMode}>
                  {"Select"}
                </div>
              </MenuOption>
              <MenuOption>
                <div className={"cursor-pointer"} onClick={handleRefresh}>
                  {"Refresh"}
                </div>
              </MenuOption>
            </div>
          </MuiPopover>
        </div>
      </div>
    </div>
  );
};

export default AsinsViewToolbarM;
