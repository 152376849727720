import { FormControl, FormControlLabel, Menu, MenuItem, Radio, RadioGroup, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import MCheckbox from "../../Atoms/MCheckbox";
import MButton from "../../Atoms/MButton";
import { useSelector } from "react-redux";
import { UserRoleSelector } from "../../../store/user/user.selector";
import { showProductVisibilityFilter } from "../../../utils/helpers/priviligesChecks";
import classes from "./index.module.css";
import MTypography from "../../Atoms/MTypography";

interface DefaultMenu {
  label: string;
  value: any;
}
interface Props {
  open: boolean;
  anchorEl: any;
  onClose: () => void;
  options: DefaultMenu[];
  handleFilterChange: (value: string, showInternalProduct: boolean, showApprovedChecked: boolean) => void;
  hideInternalUsersProducts: boolean;
  productLiveFilter: string;
  hideProductVisibilityFilter?: boolean;
  showApprovedProducts: boolean;
}

const FilterMenu: React.FC<Props> = (props) => {
  const {
    open,
    anchorEl,
    onClose,
    options,
    handleFilterChange,
    hideInternalUsersProducts,
    productLiveFilter,
    hideProductVisibilityFilter,
    showApprovedProducts
  } = props;
  const userRole = useSelector(UserRoleSelector);
  const [selectedRadio, setSelectedRadio] = React.useState<string>(productLiveFilter);
  const [checkbox, setCheckbox] = useState(hideInternalUsersProducts);
  const [showApprovedCheckBox, setShowApprovedCheckBox] = useState(showApprovedProducts);

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    setCheckbox(hideInternalUsersProducts);
    setShowApprovedCheckBox(showApprovedProducts)
    setSelectedRadio(productLiveFilter);
  }, [hideInternalUsersProducts, productLiveFilter, showApprovedProducts]);

  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedRadio(event.target.value);
  };

  const handleClick = (clickType: string) => {
    if (clickType === "applyFilters") {
      handleFilterChange(selectedRadio, checkbox, showApprovedCheckBox);
      onClose();
    } else {
      onClose();
      setCheckbox(hideInternalUsersProducts);
      setShowApprovedCheckBox(showApprovedCheckBox)
      setSelectedRadio(productLiveFilter);
    }
  };

  return (
    <>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={onClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        sx={{ padding: "8px 8px" }}
      >
        <FormControl component="fieldset">
          <Typography variant="h6" className={`${classes.Headers} pt-3 px-3 pb-2`}>
            Publish Status
          </Typography>
          <RadioGroup
            aria-label="publish-status"
            name="publish-status"
            value={selectedRadio}
            onChange={(e) => {
              handleRadioChange(e);
            }}
            sx={{ paddingLeft: "16px" }}
          >
            {options?.map((mItem: DefaultMenu) => (
              <FormControlLabel
                key={mItem.label}
                value={mItem.value}
                control={<Radio />}
                label={mItem.label}
                sx={{ height: "24px", mb: "10px" }}
                classes={{
                  label: selectedRadio === mItem.value ? classes.ActiveLabel : classes.NonActiveLabel,
                }}
              />
            ))}
          </RadioGroup>
        </FormControl>
        {/* will only show to Owner, Internal or Admin */}
        {showProductVisibilityFilter(userRole) && !hideProductVisibilityFilter && (
          <span>
            <Typography variant="h6" className={`${classes.Headers} pt-1 px-3 pb-2 `}>
              Product Visibility
            </Typography>
            <MenuItem
              value={"hide_internal_users"}
              className={`p-0 ${classes.MenuItem}`}
              sx={{ paddingLeft: "6px !important", mb: 1.25 }}
              onClick={() => setCheckbox(!checkbox)}
            >
              <MCheckbox variant={"square"} checked={checkbox} size="small" />
              <MTypography variant="subtitle1" customClass={checkbox ? classes.ActiveColor : classes.NonActiveColor}>
                Hide Internal User Products
              </MTypography>
            </MenuItem>
            <MenuItem
              value={"isApprovedProducts"}
              className={`p-0 ${classes.MenuItem}`}
              sx={{ paddingLeft: "6px !important" }}
              onClick={() => setShowApprovedCheckBox(!showApprovedCheckBox)}
            >
              <MCheckbox variant={"square"} checked={showApprovedCheckBox} size="small" />
              <MTypography variant="subtitle1" customClass={showApprovedCheckBox ? classes.ActiveColor : classes.NonActiveColor}>
                Show Approved Products
              </MTypography>
            </MenuItem>
          </span>
        )}
        <div className={`d-flex justify-content-end gap-2 px-3 mw-100 pt-4 pb-1 mt-3 ${classes.ButtonsContainer}`}>
          <MButton variant="outlined" size={"small"} onClick={() => handleClick("cancel")}>
            Cancel
          </MButton>
          <MButton size={"small"} onClick={() => handleClick("applyFilters")}>
            Apply Filters
          </MButton>
        </div>
      </Menu>
    </>
  );
};

export default FilterMenu;
